// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

@import "~@fortawesome/fontawesome-free/css/all";

// Bootstrap
@import '~mdb-ui-kit/css/mdb.min.css';
@import '~boxicons/css/boxicons.min.css';
